$(document).ready(function () {

	// ANCHOR Mise en place du geocoder 
	
	let map_accesstoken = "pk.eyJ1IjoiY3ljbGFibGUtd2ViIiwiYSI6ImNrZnNjNngwMTA3cDYycWxyZWcxNXgydWsifQ.DTC0Dak_n07HwBOCjXDzoA";

	var geocoder = new MapboxGeocoder({
		accessToken: map_accesstoken,
		countries: 'fr',
		language: 'fr-FR',
		minLength: 3,
		placeholder: "Rechercher par adresse, ville...",
	});

	var geocoderModal = new MapboxGeocoder({
		accessToken: map_accesstoken,
		countries: 'fr',
		language: 'fr-FR',
		minLength: 3,
		placeholder: "Rechercher par adresse, ville...",
	});

	// ANCHOR On ajoute le geocoding
	if(geocoder && $('#store-geocoding').length){
		geocoder.addTo('#store-geocoding');
	}

	// ANCHOR On ajoute le geocoding
	if(geocoderModal && $('#store-geocoding-modal').length){
		geocoderModal.addTo('#store-geocoding-modal');
	}

	// Convertisseur degrés en radius 
	function deg2rad(deg) {
		return deg * (Math.PI/180)
	}

	// Distance entre deux points en km
	function getDistanceBetweenPointsNew(lat1, lon1, lat2, lon2) {
		var R = 6371; // Radius of the earth in km
		var dLat = deg2rad(lat2-lat1);
		var dLon = deg2rad(lon2-lon1); 
		var a = 
			Math.sin(dLat/2) * Math.sin(dLat/2) +
			Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
			Math.sin(dLon/2) * Math.sin(dLon/2)
			; 
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
		var d = R * c;
		return d.toFixed(2);
	}

	// ANCHOR Recherche geocoding

	function filterStores(container, lat_search, lng_search, auto_select){
		// Indication de chargement
		$(container + ' .mapboxgl-ctrl-geocoder').addClass('loading');

		// On masque les options
		$(container + ' .stores-list .store-option').animate({opacity:0});
		$(container + ' .stores-list').animate({opacity:0}, 250, function() {

			// On calcule la distance pour chaque magasin
			$(container + ' .stores-list .store-option').each(function( key, store ) {
				let lat_store = $(store).data('lat');
				let lng_store = $(store).data('lng');
				let distance = getDistanceBetweenPointsNew(lat_search, lng_search, lat_store, lng_store);
				$(store).attr('data-distance', distance);
			})

			// On trie les magasins par la distance
			let filtered_list = $(container + ' .stores-list .store-option').sort(function( a, b ) {
				let result = $(a).data('distance') - $(b).data('distance');
				return result;
			})

			// On actualise la liste masquée
			$(container + ' .stores-list').html(filtered_list);

			// On affiche la liste
			$(container + ' .stores-list').css({ opacity: 1 });
			setTimeout(function(){
				$(container + ' .mapboxgl-ctrl-geocoder').removeClass('loading');
				$(container + ' .stores-list .store-option').animate({ opacity: 1 }, 250);
				$(container + ' .stores-list').animate({ scrollTop: 0 }, 250);
			}, 500);
			
			if(auto_select){
				$(container + ' .stores-list .store-option' ).first().trigger( "click" );
			}

			$('[data-toggle="tooltip"]').tooltip();
		});
	}

	let lastGeocode = "";

	// Lors d'une recherche par adressse
	geocoder.on('result', function (e) {
		// Récupération des coordonnées de la recherche
		let lat_search = e.result.center[1];
		let lng_search = e.result.center[0];
		// Vérification anti recherche doublon
		if(e.result.center.toString() !== lastGeocode){
			lastGeocode = e.result.center.toString();
			// Filtre des magasins
			filterStores("#store-search-container", lat_search, lng_search);
		}
	});

	// Lors d'une recherche par adressse
	geocoderModal.on('result', function (e) {
		// Récupération des coordonnées de la recherche
		let lat_search = e.result.center[1];
		let lng_search = e.result.center[0];
		// Vérification anti recherche doublon
		if(e.result.center.toString() !== lastGeocode){
			lastGeocode = e.result.center.toString();
			// Filtre des magasins
			filterStores("#store-search-container-modal", lat_search, lng_search);
		}
	});

	// ANCHOR Géolocalisation

	function geo_success(position) {
		$('#store-geolocate .btn-text').text('Localisation effectuée !');
		$('#store-geolocate-modal .btn-text').text('Localisation effectuée !');
		$('.store-select-toggle .mapboxgl-ctrl-geocoder--input').text('Votre position');

		// Récupération de la positon
		let lat_search = position.coords.latitude;
		let lng_search = position.coords.longitude;
		// Vérification anti recherche doublon
		if(position.coords.toString() !== lastGeocode){
			lastGeocode = position.coords.toString();
			// Filtre des magasins
			filterStores("#store-search-container", lat_search, lng_search, 1);
			filterStores("#store-search-container-modal", lat_search, lng_search, 1);
		}
	}

	function geo_error(btn) {
		// Erreur de géolocalisation / refus
		$('#store-geolocate .btn-text').html('Localisation impossible ! <br> Essayez la recherche textuelle.');
		$('#store-geolocate').attr('disabled', 'disabled');
	}

	const geo_options = {
		enableHighAccuracy: true,
		maximumAge        : 30000,
		timeout           : 27000
	};

	// ANCHOR Au clic sur la géolocalisation (popup menu)
	$('#store-geolocate').on('click', function(){
		$("#popup-stores").addClass( "manual-hover" );
		$('#store-geolocate .btn-text').text('Géolocalisation en cours...');
		navigator.geolocation.watchPosition(geo_success, geo_error, geo_options);
	})

	// ANCHOR Au clic sur la géolocalisation (modal)
	$('#store-geolocate-modal').on('click', function(){
		$('#store-geolocate-modal .targeted').show();
		$('#store-geolocate-modal .not-targeted').hide();
		navigator.geolocation.watchPosition(geo_success, geo_error, geo_options);
	})

	// ANCHOR Sélection d'un magasin 

	// Au clic sur une option
	$('.stores-list').on('click', '.store-option', function() {
		if(!$(this).hasClass('disabled')){
			// Définition du magasin sélectionné
			$('.store-selected').removeClass('store-selected');
			$(this).addClass('store-selected');
			$('.store-select-toggle .select-field-text').text($('.store-selected .store-name-value').text());
			$('.store-select-toggle').addClass('store-selected');
	
			// Carte fixe prévisualisant l'emplacement du magasin avec marker custom
			let mapbox_map_url = "https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/url-https%3A%2F%2Flyon.cyclable.com%2Fwp-content%2Fthemes%2Fcyclable-magasin-2019%2Fassets%2Fimg%2Fstore_marker.png("+$(this).data('lng')+","+$(this).data('lat')+")/"+$(this).data('lng')+","+$(this).data('lat')+",14.25,0,60/330x100?access_token="+map_accesstoken;
			$('.preview-selected-store .store-static-map').attr('src', mapbox_map_url);

			let store_stock_type = $(this).attr('data-status-stock');
			$('#selectStoreModal .store-test-alert').hide();
			$('#selectStoreModal .store-retrait-alert').hide();
			$('#selectStoreModal .store-stock-alert').hide();

			if(store_stock_type == 2) {
				$('#selectStoreModal .store-retrait-alert').show();
			}
			
			if(store_stock_type == 3) {
				$('#selectStoreModal .store-test-alert').show();
			}

			if(store_stock_type == 4) {
				$('#selectStoreModal .store-stock-alert').show();
			}
			
			// Informations de contact (téléphone)
			let store_phone = $(this).attr('data-phone');
			let store_phone_html = $('.selected-store-contact-info').find('.store-phone');
			if( !store_phone ) {
				$(store_phone_html).addClass('hidden');
			} else {
				$(store_phone_html).removeClass('hidden');
				$(store_phone_html).find('.link-text').text(store_phone);
				$(store_phone_html).find('a').attr('href', 'tel:' + store_phone);
			}
	
			// Informations de contact (mail)
			let store_id = $(this).attr('data-storeid');
			let store_mail_html = $('.selected-store-contact-info').find('.store-mail');
			if( !store_id ) {
				$(store_mail_html).addClass('hidden');
			} else {
				$(store_mail_html).removeClass('hidden');
				$(store_mail_html).find('a').attr('data-src', '/contactez-nous?id_store=' + store_id + '&get_message=1');
			}
			
			// Lien de prévisualisation Google Maps 
			let google_maps_link = encodeURI("https://www.google.com/maps?q="+$(this).data('address'));
			$('.preview-selected-store .preview-selected-store-link').attr('href', google_maps_link);

			// Si le magasin n'est pas "sélectionnable"
			let store_url = $(this).attr('data-url');
			$('#store-not-selectable-redirect').attr('href', store_url ? store_url : '#');
			let is_store_selectable = $(this).attr('data-selectable') == '1';
			if(!is_store_selectable){
				$('.submit-store-selection').hide();
				$('#store-not-selectable').show();
			} else {
				$('.submit-store-selection').show();
				$('#store-not-selectable').hide();
	
				// On rend le bouton de validation actif
				$('.submit-store-selection').removeAttr('disabled');
			}
	
			// Affichage de la prévisualisation
			$('.preview-selected-store .preview-selected-store-link').show();

			// Masquer la liste et afficher les actions de validation
			$('.store-search-container').collapse('hide');
			$('.store-search-container-modal').collapse('hide');
			$('.store-actions').slideDown();
			
			setTimeout(function(){ 
				$("#popup-stores").removeClass( "manual-hover" );
			}, 5000);
		}
	});

	// ANCHOR À l'ouverture du menu de sélection
	$('.store-select-toggle').on('click', function(){
		$(this).toggleClass('menu-open');
		$(this).parents(".modal-body:first").find('.store-actions').slideToggle();
	})

	// Quand on ouvre le modal de sélection de boutique
	$('#selectStoreModal').on('show.bs.modal', function (event) {
		let selectedStore = $(this).find('.store-option.store-selected');

		// Si une option est choisie mais non validée
		if(selectedStore.length){
			// On réinitialise la liste des choix
			$(selectedStore).removeClass('store-selected');
			$('.store-select-toggle .select-field-text').text("Sélectionner un magasin");
			$('.store-select-toggle').removeClass('store-selected');
			$('.preview-selected-store .preview-selected-store-link').hide();
			$('.store-search-container').collapse('show');
			$('.store-search-container-modal').collapse('show');
			$('.store-actions').slideUp();
			$('.submit-store-selection').attr('disabled', 'disabled');
		}
	})

	// ANCHOR Sauvegarde de l'identifiant
	var selectStoreUrl = $('#selectStoreModal').find('form').attr('action');

	function saveSelectedStore(cyclable_store_selected, addToCart, redirect = true){
		$.ajax({
			type: 'POST',
			url: selectStoreUrl,
			cache: false,
			async: true,
			data: {
				action: 'setSelectedStore',
				cyclable_store_selected : cyclable_store_selected,
				ajax: true,
			},
			success: function( data ) {
				if(data){
					try {
						data = $.parseJSON(data);
						if ( data.error ) {
							console.warn( data.message );
						} else {
							if(redirect) {
								// ANCHOR Ajouter au panier si on est sur la page produit et que c'est dispo
								if(addToCart){
									sessionStorage.setItem('cyclable_add_product_cart', true);
								}

								location.reload();
								return false;
							} else {
								return true;
							}
						}
					} catch (e) {
						console.warn( e );
						location.reload();
						return false;
					}
				}
			},
			error: function( XMLHttpRequest, textStatus, errorThrown ) {
			}
		});
	}

	$('.submit-store-selection').on('click', function(){
		let cyclable_store_selected = $("input[name='store-id']:checked").val();
		if(cyclable_store_selected){
			let addToCart = false;
			if($('.submit-store-selection').hasClass('submit-add-cart')){
				addToCart = true;
			}
			saveSelectedStore(cyclable_store_selected, addToCart);
		}
	})

	$('.reset-store').on('click', function(){

		$.ajax({
			type: 'POST',
			url: selectStoreUrl,
			cache: false,
			async: true,
			data: {
				action: 'unsetSelectedStore',
				ajax: true,
			},
			success: function( data ) {
				if(data){
					try {
						data = $.parseJSON(data);
						if ( data.error ) {
							console.warn( data.message );
						} else {
							// document.cookie = "cyclable_store_selected=; expires=Thu, 01 Jan 1970 00:00:00 UTC;"
							location.reload();
							return false;
						}
					} catch (e) {
						console.warn( e );
						location.reload();
						return false;
					}
				}
			},
			error: function( XMLHttpRequest, textStatus, errorThrown ) {
			}
		});
	})

	$('.reset-store-popup, .reset-store-popup-cancel').on('click', function(){
		$("#popup-stores").addClass( "manual-hover" );
		$('#popup-preview-store-container').slideToggle();
		$('#popup-select-store-container').slideToggle();
		setTimeout(function(){ $("#popup-stores").removeClass( "manual-hover" ); }, 5000);
	});

	// ANCHOR Affichage des stocks 
	var unsortedList = $('#selectStoreModal .store-option');
	var productFilterBy = null;
	var listAvailableStores = [];
	var listRetraitStores = [];
	var listDisabledStores = [];

	$.fn.refresh = function () {
		return $(this.selector);
	};

	// Rafraichissement de la liste des magasins
	$('#selectStoreModal').on('show.bs.modal', function (event) {
		let trigger = $(event.relatedTarget).data('trigger');
		productFilterBy = $(event.relatedTarget).parents('.ajax_block_product').first();

		listAvailableStores = [];
		listRetraitStores = [];
		listStockReferenceStores = [];
		listStockTestStores = [];
		listDisabledStores = [];

		if(productFilterBy.length > 0) {
			if($(productFilterBy).find('.available-warehouses-list').length) {
				listAvailableStores = $(productFilterBy).find('.available-warehouses-list').val().trim().split(',');
			}
			
			if($(productFilterBy).find('.retrait-warehouses-list').length) {
				listRetraitStores = $(productFilterBy).find('.retrait-warehouses-list').val().trim().split(',');
			}
			
			if($(productFilterBy).find('.supermodel-warehouses-list').length) {
				listStockReferenceStores = $(productFilterBy).find('.supermodel-warehouses-list').val().trim().split(',');
			}
			
			if($(productFilterBy).find('.test-warehouses-list').length) {
				listStockTestStores = $(productFilterBy).find('.test-warehouses-list').val().trim().split(',');
			}

			if($(productFilterBy).find('.disabled-warehouses-list').length) {
				listDisabledStores = $(productFilterBy).find('.disabled-warehouses-list').val().trim().split(',');
			}
		}

		filterStoreSelectModal(trigger);
	})

	// ANCHOR À la sélection d'un magasin de la liste, on génère une image de preview de localisation
	$('#storecarrier select').on('select2:select', function (e) {
		let store_warehouse = $('#storecarrier option:selected').attr('data-entrepot');
		if(store_warehouse) {
			saveSelectedStore(store_warehouse, false, false);
		}
	});

	function filterStoreSelectModal(trigger){
		// Si on est pas sur la page produit
		if( $("body").attr('id') != "product" ) {
			if (unsortedList && trigger == "menu") {
				$('#selectStoreModal .stores-list').find('.icon').hide();
				$(unsortedList).appendTo('#selectStoreModal .stores-list');
				productFilterBy = null;
				listAvailableStores = [];
				listRetraitStores = [];
				listStockReferenceStores = [];
				listStockTestStores = [];
				listDisabledStores = [];
			} else if (productFilterBy && trigger != "product-page") {
				sortStockProductListing();
			}
		}
	}

	function sortStockProductListing() {
		var sortedList = $('#selectStoreModal').find('.store-option');
		sortedList = sortedList.refresh();

		// On retire les icones et les classes de filtrages
		$('#selectStoreModal .store-option').removeClass('store-has-stock web-has-stock store-retrait-stock store-test-stock out-of-stock no-stock');
		$('#selectStoreModal .store-option .icon').remove();

		sortedList = $(sortedList).sort(sortStoresByStock);
		$(sortedList).appendTo('#selectStoreModal .stores-list');
		$('#selectStoreModal').addClass('stock-sorted');

		$('#selectStoreModal .store-option.store-has-stock').each(function (key, value) {
			$(this).attr('data-status-stock', 4);
			$(this).prepend('<span class="icon icon-1x material-icons-filled">check_circle</span>');
			$(this).find('.icon').attr('title', "Disponible en magasin");
			$(this).find('.icon').attr('data-toggle', "tooltip");
			$(this).find('.icon').attr('data-placement', "right");
		});

		$('#selectStoreModal .store-option.store-test-stock').each(function (key, value) {
			$(this).attr('data-status-stock', 3);
			$(this).prepend('<span class="icon icon-1x material-icons-outlined">check_circle</span>');
			$(this).find('.icon').attr('title', "Visible en magasin");
			$(this).find('.icon').attr('data-toggle', "tooltip");
			$(this).find('.icon').attr('data-placement', "right");
		});

		$('#selectStoreModal .store-option.store-retrait-stock').each(function (key, value) {
			$(this).attr('data-status-stock', 2);
			$(this).prepend('<svg class="icon icon-stock-retrait"><path d="M18.3,4.5c0-0.3-0.2-0.7-0.5-0.8c-1.2-0.5-2.4-1-3.5-1.6C14,2,13.8,1.9,13.5,1.8c0,0,0,0,0,0c-1.1-0.5-2.3-1-3.4-1.5 C9.6,0,9.2-0.1,8.6,0.1C8.2,0.3,7.9,0.4,7.5,0.6c-2.3,1-4.6,2.1-7,3.1h0c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1c0,0,0,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.2C0.1,4.2,0,4.4,0,4.6v10.1c0,0.3,0.2,0.7,0.5,0.9l8.2,3.6c0.1,0.1,0.3,0.1,0.4,0.1c0,0,0.1,0,0.1,0s0.1,0,0.1,0 c0.1,0,0.3,0,0.4-0.1c2.7-1.2,5.4-2.4,8.2-3.6c0.3-0.1,0.5-0.5,0.5-0.9L18.3,4.5C18.3,4.6,18.3,4.6,18.3,4.5L18.3,4.5z M7,8.4 C7.4,8.6,7.8,8.8,8.2,9v7.8C6.1,15.8,4,14.9,2,14V6.1C3.7,6.9,5.3,7.6,7,8.4z M10.2,8.9c0.8-0.4,1.6-0.8,2.4-1.1c0.5-0.2,0.9-0.4,1.3-0.6c0.8-0.4,1.6-0.7,2.4-1.1V14c-2,0.9-4.1,1.8-6.2,2.7V8.9z M14.9,4.6c-0.7,0.3-1.3,0.6-2,0.9 C11.7,6,10.4,6.6,9.2,7.2C8.6,6.9,8.1,6.7,7.6,6.5c1.8-0.9,3.6-1.8,5.5-2.7C13.6,4,14.3,4.3,14.9,4.6L14.9,4.6z M9.2,2 c0.5,0.2,1,0.4,1.5,0.7C8.8,3.6,7,4.5,5.2,5.4L3.4,4.6C3.4,4.6,9.2,2,9.2,2z"/></svg>');
			$(this).find('.icon').attr('title', "Disponible en retrait sous 5j");
			$(this).find('.icon').attr('data-toggle', "tooltip");
			$(this).find('.icon').attr('data-placement', "right");
		});

		$('#selectStoreModal .store-option.out-of-stock').each(function (key, value) {
			$(this).attr('data-status-stock', 1);
			$(this).prepend('<span class="icon icon-1x material-icons-outlined">info</span>');
			$(this).find('.icon').attr('title', "Contactez le magasin pour connaître sa disponibilité");
			$(this).find('.icon').attr('data-toggle', "tooltip");
			$(this).find('.icon').attr('data-placement', "right");
		});

		$('#selectStoreModal .store-option.no-stock').each(function (key, value) {
			$(this).attr('data-status-stock', 0);
			$(this).prepend('<span class="icon icon-1x material-icons-outlined">cancel</span>');
			$(this).find('.icon').attr('title', "Indisponible dans ce magasin");
			$(this).find('.icon').attr('data-toggle', "tooltip");
			$(this).find('.icon').attr('data-placement', "right");
		});

		// On active les nouveaux tooltip
		$('[data-toggle="tooltip"]').tooltip();
		
		$('#selectStoreModal .stores-list').find('.icon').show();
	}

	function sortStoresByStock(a, b) {
		const A_storeId = $(a).find('input[name=store-id]').val();
		const B_storeId = $(b).find('input[name=store-id]').val();
		let A_value = 0;
		let B_value = 0;
	
		if (listAvailableStores.includes(A_storeId)) {
			A_value = 3;
			$(a).addClass('store-has-stock');
		} else if (listStockTestStores.includes(A_storeId) || listStockReferenceStores.includes(A_storeId)) {
			A_value = 2;
			$(a).addClass('store-test-stock');
		} else if (listRetraitStores.includes(A_storeId)) {
			A_value = 1;
			$(a).addClass('store-retrait-stock');
		} else {
			$(a).addClass('out-of-stock');
		}
	
		if (listAvailableStores.includes(B_storeId)) {
			B_value = 3;
			$(b).addClass('store-has-stock');
		} else if (listStockTestStores.includes(B_storeId) || listStockReferenceStores.includes(B_storeId)) {
			B_value = 2;
			$(b).addClass('store-test-stock');
		} else if (listRetraitStores.includes(B_storeId)) {
			B_value = 1;
			$(b).addClass('store-retrait-stock');
		} else {
			$(b).addClass('out-of-stock');
		}
	
		$(a, b).find('.icon').attr('data-toggle', 'tooltip').attr('data-placement', 'right');
	
		return B_value - A_value;
	}
});